.subFooter {
  background: var(--color-primary-500);
  color: white;
  display: flex;
  justify-content: center;
  padding: var(--spacing-5);
  flex-flow: row wrap;
  > div {
    margin: 0 auto;
    max-width: 1312px;
  }
  [data-desktop-only] {
    display: none;
  }

  @media (--desktop) {
    [data-desktop-only] {
      display: inline-block;
      margin: 0px var(--spacing-6);
    }
    [data-not-desktop] {
      display: none;
    }
  }
  @media (max-width: 1023px) {
    flex-direction: column;
  }
}
.divider {
  margin: 0px var(--spacing-6);
  @media (max-width: 1023px) {
    display: none;
  }
}
